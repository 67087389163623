<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>고객사 관리</p>
            </div>
            


            <!-- 서브페이지 내용 -->
            <div class="con-wrap">
                <MypimsPersonalityPopup v-if="pop.isShow" @close="pop.isShow=false"></MypimsPersonalityPopup>

                <CarrotTitle title="고객사 평가 관리">
                    - 파일이 첨부된 평가만 view 버튼이 노출됩니다.  <br>
                    - 관리 버튼 클릭 시 관리페이지가 노출됩니다. 평가 파일첨부가 가능합니다. 
                </CarrotTitle>
                <div class="board">
                    <div class="mt-40">
                        <div class="board-search">
                            <select class="w-80px mb-30 board-search-select">
                                <option value="">이름</option>
                                <option value="">이름</option>
                                <option value="">이름</option>
                                <option value="">이름</option>
                            </select>
                            <input type="text" class="w-400px board-search-input">
                            <button class="btn-default mb-50 float-left">검색</button>
                        </div>
                        <div>
                            <div class="mt-50 mb-20">
                                <CarrotDept></CarrotDept>
                                <CarrotTeam></CarrotTeam>
                            </div>
                            <table class="table-col">
                                <colgroup>
                                    <col width="60">
                                    <col width="180">
                                    <col width="180">
                                    <col width="180">
                                    <col width="100">
                                    <col width="100">
                                    <col width="100">
                                    <col width="100">
                                    <col width="100">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>본부</th>
                                        <th>팀</th>
                                        <th>이름</th>
                                        <th>MBTI</th>
                                        <th>애니어그램</th>
                                        <th>G:CAT</th>
                                        <th>기타자료</th>
                                        <th>관리</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>4</td>
                                        <td>글로벌교육사업본부</td>
                                        <td>Challenge팀</td>
                                        <td>Yulia(김소연)</td>
                                        <td>-</td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view" @click="pop.isShow=true">view</span></td>
                                        <td><router-link to="/MypimsPersonalityView"><span class="btn-view">view</span></router-link></td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>이러닝융합서비스</td>
                                        <td>솔루션개발팀</td>
                                        <td>Min K(김민정)</td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view" @click="pop.isShow=true">view</span></td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>이러닝융합서비스</td>
                                        <td>솔루션개발팀</td>
                                        <td>Judy(박태정)</td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                        <td>-</td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>이러닝융합서비스</td>
                                        <td>솔루션개발팀</td>
                                        <td>Sophie J(정수빈)</td>
                                        <td>-</td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                        <td>-</td>
                                        <td><span class="btn-view">view</span></td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>글로벌교육사업본부</td>
                                        <td>Challenge팀</td>
                                        <td>Yulia(김소연)</td>
                                        <td>-</td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>이러닝융합서비스</td>
                                        <td>솔루션개발팀</td>
                                        <td>Min K(김민정)</td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>이러닝융합서비스</td>
                                        <td>솔루션개발팀</td>
                                        <td>Judy(박태정)</td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                        <td>-</td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>이러닝융합서비스</td>
                                        <td>솔루션개발팀</td>
                                        <td>Sophie J(정수빈)</td>
                                        <td>-</td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                        <td>-</td>
                                        <td><span class="btn-view">view</span></td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>글로벌교육사업본부</td>
                                        <td>Challenge팀</td>
                                        <td>Yulia(김소연)</td>
                                        <td>-</td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>이러닝융합서비스</td>
                                        <td>솔루션개발팀</td>
                                        <td>Min K(김민정)</td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>이러닝융합서비스</td>
                                        <td>솔루션개발팀</td>
                                        <td>Judy(박태정)</td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                        <td>-</td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>이러닝융합서비스</td>
                                        <td>솔루션개발팀</td>
                                        <td>Sophie J(정수빈)</td>
                                        <td>-</td>
                                        <td><span class="btn-view">view</span></td>
                                        <td><span class="btn-view">view</span></td>
                                        <td>-</td>
                                        <td><span class="btn-view">view</span></td>
                                    </tr>
                                </tbody>
                            </table>
                            <CarrotPaging></CarrotPaging>
                            <router-link to="/MypimsPersonalityAdd"><button class="btn-default float-right mt-30">등록</button></router-link>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브페이지 내용 끝 -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { reactive } from 'vue'
import MypimsPersonalityPopup from '@/components/popup/personality/MypimsPersonalityPopup.vue'


export default {
    components: {
        MypimsPersonalityPopup
    },
    setup() {
        const pop = reactive({
            // Popup 노출 여부
            isShow: true
        });
        
        return {pop};
    }

}
</script>

<style lang="scss" scoped>
</style>