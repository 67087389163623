<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-400px">
            <div>
                <p class="title">인/적성평가 관리</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-view mt-20 w-100per">
                    <colgroup>
                        <col width="150">
                        <col width="*">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>MBTI</th>
                            <td>
                                <input type="file">
                            </td>
                        </tr>
                        <tr>
                            <th>에니어그램</th>
                            <td>
                                <input type="file">
                            </td>
                        </tr>
                        <tr>
                            <th>G:CAT</th>
                            <td>
                                <input type="file">
                            </td>
                        </tr>
                        <tr>
                            <th>기타자료</th>
                            <td>
                                <input type="file">
                            </td>
                        </tr>
                    </thead>
                </table>
            </div>
            <div class="layer-btn mt-10 w-100per dp-table">
                <button @click="pop.onClose()" class="btn-default float-right">확인</button>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'

export default {
    name: 'MypimsPersonalityPopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    setup(props, {emit}) {
        const pop = reactive({
            onClose: () => {
                emit('close');
            }
        });
        
        return {pop};
    }
}
</script>
<style lang="scss" scoped>
    .btn-close {
        float: right;
        cursor: pointer;
        padding: 5px;
        margin-top:-5px;
        margin-right:-5px;
    }
</style>